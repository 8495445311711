import type { Locale, Localizable } from '@models/contentful';

/**
 * Allows to check if it is a real user browser request due to "window" is not available during build process.
 * Any "window" usage must be with checking if it is a real user browser, otherwise your builds will fail.
 */
export const isBrowser = typeof window !== 'undefined';

/** Get current environment */
export const environment = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

export const isProductionEnv = environment === 'production';

export const defaultLocale: Locale = 'en';

export const availableLocales: Locale[] = [ defaultLocale, 'de', 'fr' ];

export const isContentAvailableForLocale = (content: Localizable, locale: Locale) => {
    if (!content.languages || !content.languages.locales.length)
        return true;

    return content.languages.locales.includes(locale);
};
